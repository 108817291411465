import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink, Redirect } from "react-router-dom";
import "./NavBackend.css";
import { Form, Button, Container } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import { connect } from "react-redux";

class NavBackend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      redirect: false,
      message: "",
    };
  }

  getData = () => {
    const search = { searchValue: this.state.search };

    fetch("/search", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(search),
    })
      .then((results) => results.json())
      .then((results) => {
        if (results.message) {
          this.setState({ message: results.message });
        } else {
          // this.props.search helps us add our search results to the store so as to be accessed from anywhere.
          this.props.search(results);
          //TODO: We now need to redirect to items detail page. Add that code below this, change the value of "redirect" in the state
          this.setState({ redirect: true });
        }
      })
      .catch((error) => console.error(error));
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.getData();
    }
  };

  handleChange = (event) => {
    this.setState({ search: event.target.value });
  };

  render() {
    // TODO: Write a condition redirects to "/itemsdetails" if the value of "redirect" in the state is true
    if (this.state.redirect === true) {
      return <Redirect to="/itemdetails" />;
    }
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" className=" NavBackend">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="container-fluid  pl-3 flex-lg-row align-items-end">
              <Navbar.Brand href="#home"></Navbar.Brand>

              <Nav.Item className="SearchBar">
                <Form inline onSubmit={this.getData}>
                  <Form.Group>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={this.state.search}
                      onKeyDown={this.handleKeyPress}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  {this.state.message && <span>{this.state.message}</span>}
                </Form>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Button className="requests-button">
                    <FaShoppingCart />
                    <NavLink to="/requests" className="requests-style">
                      My Requests
                    </NavLink>
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

const mapDispatchToProps = (dispatch) => ({
  search: (item) => dispatch({ type: "SEARCH_ITEM", item: item }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBackend);
