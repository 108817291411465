import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Container, Row, Col } from "react-bootstrap";
import profileLogo from "../../static/img/Dashboard/profileLogo.svg";
import "./SideNavDashboard.css";
import { Redirect } from "react-router-dom";

class SideNavDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        email: "",
        first_Name: "",
        last_Name: "",
        role: "",
      },
      signin: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch("/profileDirector", {
      method: "GET",
      headers: new Headers({ Authorization: "Bearer " + this.props.token }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ profile: res });
      })
      .catch((err) => console.error(err));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ signin: true });
    this.props.dispatch({
      type: "TOGGLE_SESSION",
      token: "",
    });
  };

  render() {
    if (this.state.signin === true) {
      return <Redirect to="/signin" />;
    }

    return (
      <Container className="Dashboard">
        <Row className="d-flex flex-row no gutters justify-content-md-center">
          <Col className="sidebar">
            <div>
              <h2 className="welcome">
                Welcome {this.state.profile.first_Name}{" "}
                {this.state.profile.last_Name}
              </h2>
              <Image
                src={profileLogo}
                className="profile-image rounded-circle p-2"
                alt=""
                fluid
              />
            </div>

            <div className="dashboard-buttons">
              {this.state.profile.role === "Director" && (
                <Button className="dashboard-btn">Pending Requests</Button>
              )}
              <Button className="dashboard-btn">Sell Items</Button>
              <Button className="dashboard-btn">Request Purchase</Button>
              <Button className="dashboard-btn">
                Undersupply/Shortage Items
              </Button>
              <Button
                className="dashboard-logout"
                type="submit"
                value="submit"
                onClick={this.handleSubmit}
              >
                Log Out
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(SideNavDashboard);
