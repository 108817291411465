import React from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import { Container, Row, Col, Card } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./Compliance.css";

import complianceIcon from "../../static/img/Compliance/whiteicon.svg";

class Compliance extends React.Component {
  render() {
    return (
      <Container className="Compliance" fluid>
        <Row className="no gutters">
          <Col className="pl-0 pr-0" sm={12} md={12} lg={12}>
            <NavHeader />
          </Col>
        </Row>
        <Row className="no gutters mb-5 ">
          <Col sm={true} md={6} lg={6}>
            <h1>Compliance</h1>
            <div className="compliance-line"></div>
          </Col>
        </Row>

        <Row className="no gutters pt-5">
          <Col sm={true} md={12} lg={12}>
            <h2>
              <span className="capital-text ">UAE</span> Regulations and
              <span className="text-change"> licenses</span>
            </h2>
            <div className="uae-underline"></div>
          </Col>
        </Row>
        <Row className="no gutters pt-5">
          <Col sm={true} md={4} lg={4}>
            <Card className="">
              <Card.Body>
                <div className="mini-icon mt-4">
                  <Image src={complianceIcon} className="pl-3" alt="icon" />
                </div>
                <div className="card-border">
                  <Card.Title className="cardtitle">Sensala</Card.Title>
                  <div className="card-underline"></div>
                  <Card.Text>
                    <p className="compliance-text pt-4">
                      Is a registered onshore company with the capability to
                      import, store and supply healthcare organisations
                    </p>
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={true} md={4} lg={4}>
            <Card className="">
              <Card.Body>
                <div className="mini-icon mt-4">
                  <Image src={complianceIcon} className="pl-3" alt="icon" />
                </div>
                <div className="card-border">
                  <Card.Title className="cardtitle">We at Sensala</Card.Title>
                  <div className="card-underline"></div>
                  <Card.Text>
                    <p className="compliance-text pt-4">
                      Comply with all UAE and US requirements with regard to the
                      importation and distribution of medical equipment and
                      products.
                    </p>
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={true} md={4} lg={4}>
            <Card className="">
              <Card.Body>
                <div className="mini-icon mt-4">
                  <Image src={complianceIcon} className="pl-3" alt="icon" />
                </div>
                <div className="card-border">
                  <Card.Title className="cardtitle">
                    Sensala Provides
                  </Card.Title>
                  <div className="card-underline"></div>
                  <Card.Text>
                    <p className="compliance-text pt-4">
                      Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                      aut odit aut fugit, sed quia consequuntur magni dolores
                      eos qui.
                    </p>
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Compliance;
