import React from "react";
import { Card, Button } from "react-bootstrap";
import "./ItemCard.css";
import medicalImage from "../../static/img/Home/medical-supplies.jpg";
import { Link } from "react-router-dom";
function ItemCard({ item, name, category, expiration, handleSelectItem }) {
  return (
    <div>
      <Card style={{ width: "12rem", margin: "20px" }}>
        <div className="card-expiration">
          <Card.Img variant="top" src={medicalImage} />
          <h6 className="expiring-banner">
            Expiring in <br />
            <span>{expiration}</span>
          </h6>
        </div>
        <Card.Body style={{ padding: "0.5rem" }}>
          <Card.Text>
            <p>{name}</p>
          </Card.Text>
          <Card.Text>
            <p>{category}</p>
          </Card.Text>
          <Link to="/itemdetails" onClick={() => handleSelectItem(item)}>
            <Button className="items-button">Details</Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ItemCard;
