import React from "react";
import { Redirect } from "react-router-dom";
import { Col, Button, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "./SignUp.css";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_Name: "",
      first_NameError: "",
      last_Name: "",
      last_NameError: "",
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      entity: "",
      entityError: "",
      role: "",
      roleError: "",
      redirect: false,
    };
    this.updateFirst_NameField = this.updateFirst_NameField.bind(this);
    this.updateLast_NameField = this.updateLast_NameField.bind(this);
    this.updateEmailField = this.updateEmailField.bind(this);
    this.updatePasswordField = this.updatePasswordField.bind(this);
    this.updateEntityField = this.updateEntityField.bind(this);
    this.updateRoleField = this.updateRoleField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateFirst_NameField(event) {
    this.setState({ first_Name: event.target.value });
  }

  updateLast_NameField(event) {
    this.setState({ last_Name: event.target.value });
  }

  updateEmailField(event) {
    this.setState({ email: event.target.value });
  }

  updatePasswordField(event) {
    this.setState({ password: event.target.value });
  }

  updateEntityField(event) {
    this.setState({ entity: event.target.value });
  }

  updateRoleField(event) {
    this.setState({ role: event.target.value });
  }

  // setRedirect = () => {
  //   this.setState({ redirect: true });
  // };

  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to="" />;
  //   }
  // };

  validate = () => {
    let first_NameError = "";
    let last_NameError = "";
    let emailError = "";
    let passwordError = "";
    let roleError = "";
    let entityError = "";

    if (!this.state.first_Name) {
      first_NameError = "Please enter your first name";
    }

    if (!this.state.last_Name) {
      last_NameError = "Please enter your last name";
    }

    if (!this.state.password) {
      passwordError = "Please enter your password";
    }

    if (!this.state.email.includes("@")) {
      emailError = "Please enter the correct email address";
    }

    if (!this.state.entity) {
      entityError = "Please enter your entity";
    }

    if (!this.state.role) {
      roleError = "Please select your role";
    }

    if (
      first_NameError ||
      last_NameError ||
      passwordError ||
      emailError ||
      roleError ||
      entityError
    ) {
      this.setState({
        first_NameError,
        last_NameError,
        emailError,
        passwordError,
        roleError,
        entityError,
      });

      return false;
    }

    return true;
  };

  isEmpty = (object) => {
    return !Object.values(object).some((x) => x !== null && x !== "");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      first_Name: this.state.first_Name,
      last_Name: this.state.last_Name,
      email: this.state.email,
      password: this.state.password,
      passwordconf: this.state.passwordconf,
      entity: this.state.entity,
      role: this.state.role,
    };
    if (this.isEmpty(user)) {
      console.log("form is empty");
      return <Redirect to="/signup" />;
    }

    const isValid = this.validate();
    if (isValid) {
      fetch("/signup", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(this.state),
      })
        .then((res) => res.json())
        .then((res) =>
          res.message.includes("User Created")
            ? this.setState({ redirect: true })
            : console.log(res.message)
        )
        .catch((err) => this.setState({ flash: err.flash }));
      //   .then((res) => this.setState({ flash: res.flash }))
      //   .catch((err) => this.setState({ flash: err.flash }));
      // this.setState({ open: false });
      // console.log("form submitted");
      alert("Thank you ! Your submission has been recieved.");
    }
  };

  render() {
    return this.state.redirect ? (
      <Redirect to="/signin" />
    ) : (
      <Form onSubmit={this.handleSubmit} className="Signup">
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={this.state.first_Name}
              onChange={this.updateFirst_NameField}
            />
            <div style={{ fontSize: 12, color: "red" }}>
              {this.state.first_NameError}
            </div>
          </Form.Group>

          <Form.Group as={Col} controlId="formBasicLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={this.state.last_Name}
              onChange={this.updateLast_NameField}
            />
            <div style={{ fontSize: 12, color: "red" }}>
              {this.state.last_NameError}
            </div>
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email Address"
            value={this.state.email}
            onChange={this.updateEmailField}
          />
          <div style={{ fontSize: 12, color: "red" }}>
            {this.state.emailError}
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.updatePasswordField}
          />
          <div style={{ fontSize: 12, color: "red" }}>
            {this.state.passwordError}
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicEntity">
          <Form.Label>Entity</Form.Label>
          <Form.Control
            type="text"
            placeholder="Entity"
            value={this.state.entity}
            onChange={this.updateEntityField}
          />
          <div style={{ fontSize: 12, color: "red" }}>
            {this.state.entityError}
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicRole">
          <Form.Label>Role</Form.Label>
          <Form.Control
            as="select"
            value={this.state.role}
            onChange={this.updateRoleField}
          >
            <option value="" selected disabled hidden>
              Choose your role
            </option>
            <option>Director</option>
            <option>Manager</option>
          </Form.Control>
          <div style={{ fontSize: 12, color: "red" }}>
            {this.state.roleError}
          </div>
        </Form.Group>

        <Button type="submit" onClick={this.handleSubmit}>
          Submit
        </Button>
      </Form>
    );
  }
}

export default SignUp;
