import React from "react";
import NavHeader from "../components/NavHeader/NavHeader";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogIn from "../components/Login/Login";
import SignUp from "../components/SignUp/SignUp";
import "./LoginPage.css";

function LoginPage() {
  return (
    <Container className="Loginpage" fluid>
      <Row className="no gutters">
        <Col className="pl-0 pr-0" sm={12} md={12} lg={12}>
          <NavHeader />
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={6} lg={6} className="signup pl-0 pr-0">
          <h1>SIGN UP</h1>
          <SignUp />
        </Col>
        <Col sm={12} xs={12} md={6} lg={6} className="login">
          <h1>LOG IN</h1>
          <LogIn />
        </Col>
      </Row>
      <Row className="mt-5 no gutters">
        <Col
          sm={12}
          md={12}
          lg={12}
          className="d-flex justify-content-lg-start  justify-content-center pl-0"
        >
          <Button>
            <Link to="/">Go Back To Home Page</Link>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
