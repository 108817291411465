const initialState = {
  item: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SELECT_ITEM":
      return { ...state, item: action.item };
    case "SEARCH_ITEM":
      return { ...state, item: action.item };
    default:
      return state;
  }
}
