import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./Login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      role: "",
    };
    this.updateEmailField = this.updateEmailField.bind(this);
    this.updatePasswordField = this.updatePasswordField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateEmailField(event) {
    this.setState({ email: event.target.value });
  }

  updatePasswordField(event) {
    this.setState({ password: event.target.value });
  }

  validate = () => {
    let passwordError = "";
    let emailError = "";

    if (!this.state.password) {
      passwordError = "Please enter the correct password";
    }

    if (!this.state.email.includes("@")) {
      emailError = "Please enter the correct email address";
    }

    if (emailError || passwordError) {
      this.setState({ emailError, passwordError });
      return false;
    }

    return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      fetch("/signin", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(this.state),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.message) {
            if (res.message === "Please enter the correct password") {
              this.setState({ passwordError: res.message });
            }
            if (res.message === "Please enter the correct email address") {
              this.setState({ emailError: res.message });
            }
          } else {
            this.props.dispatch({
              type: "TOGGLE_SESSION",
              user: res.secureUser,
              token: res.token,
            });
            console.log(res.secureUser);
            this.setState({ role: res.secureUser.role });
          }
        })
        .catch((err) => console.error(err));
    }
  };

  render() {
    if (this.state.role === "Manager") {
      return <Redirect to="/profileManager" />;
    } else if (this.state.role === "Director") {
      return <Redirect to="/profileDirector" />;
    } else {
      return (
        <Form onSubmit={this.handleSubmit} className="Login">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              value={this.state.email}
              onChange={this.updateEmailField}
            />
            <div style={{ fontSize: 12, color: "red" }}>
              {this.state.emailError}
            </div>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.updatePasswordField}
            />
            <div style={{ fontSize: 12, color: "red" }}>
              {this.state.passwordError}
            </div>
          </Form.Group>
          <Button variant="danger" type="submit" onClick={this.handleSubmit}>
            Log In
          </Button>
        </Form>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
export default connect(mapStateToProps)(Login);
