import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import NavHeader from "../../components/NavHeader/NavHeader";
import sensalaPortal from "../../static/img/Home/sensalaPortal.jpg";
import patientIcon from "../../static/img/Home/patient.svg";
import hospitalIcon from "../../static/img/Home/hospital.svg";
import agileIcon from "../../static/img/Home/agileService.svg";
import medicalImage from "../../static/img/Home/medical-supplies.jpg";
import tablets from "../../static/img/Home/tablets.jpg";
import research from "../../static/img/Home/research1.jpg";
import complianceIcon from "../../static/img/Home/compliance.svg";
import "./Home.css";

class Home extends React.Component {
  render() {
    return (
      <Container className="Home" fluid>
        <Row className="no gutters">
          <Col className="pl-0 pr-0" sm={12} md={12} lg={12}>
            <NavHeader />
          </Col>
        </Row>
        <Row className="d-flex flex-row no gutters justify-content-md-center">
          <Col sm={12} md="auto" lg={6} className="title-colour">
            <h2>About Us</h2>
            <div className="title-underline"></div>
            <p className="text-colour pt-4">
              Sensala was established in 2017 and comprises a team of
              international medical supply, procurement and inventory management
              expertise.
            </p>
            <p className="text-colour">
              An outsourced, end-to-end medical supply, procurement and
              inventory management solution.
            </p>
          </Col>
          <Col sm={12} md={12} lg={1} className="pl-0 pr-0">
            <div className="line-animation mb-3"></div>
          </Col>
          <Col sm={12} md="auto" lg={5} className="portal-style">
            <Image src={sensalaPortal} className="portal-image" alt="" fluid />
            <div className="overlay-text">
              <h3>Welcome To</h3>
              <h4>Sensalas' Portal</h4>
              <Link to="/signin">
                <Button>Click to continue to Sensala Portal</Button>
              </Link>
            </div>
          </Col>
        </Row>
        {/* Scroll down Section  */}
        <Row>
          <Col
            sm={true}
            md={12}
            lg={12}
            className="arrow-style d-flex flex-column align-items-center"
          >
            <p>
              Scroll down <br /> for more information
            </p>
            <svg
              id="i-chevron-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              fill="none"
              stroke="rgb(208,208,208)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            >
              <path d="M30 12 L16 24 2 12" />
            </svg>
            <svg
              id="i-chevron-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              fill="none"
              stroke="rgb(208,208,208)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            >
              <path d="M30 12 L16 24 2 12" />
            </svg>

            <svg
              id="i-chevron-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              fill="none"
              stroke="rgb(208,208,208)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            >
              <path d="M30 12 L16 24 2 12" />
            </svg>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <div className="break-line mt-5 pt-5"></div>
          </Col>
        </Row>

        {/* Collaborative Section  */}
        <Row className="flex-row no gutters mt-4 mb-3">
          <Col className="mini-title-style-red" md={12} lg={12}>
            <h3>Collaborative</h3>
          </Col>
        </Row>
        <Row
          className=" d-flex flex-row
         no gutters mt-2"
        >
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex flex-column align-items-center"
          >
            <div className="colour-wrapper">
              <Image src={patientIcon} alt="" className="icons" fluid />
            </div>
            <div className="collab-style-line"></div>
            <p className="mini-text-style">
              Patient <br /> Centered
            </p>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex flex-column align-items-center"
          >
            <div className="colour-wrapper">
              <Image src={agileIcon} alt="" className="icons" fluid />
            </div>
            <div className="collab-style-line"></div>
            <p className="mini-text-style">
              Agile Organisational <br />
              Ethos
            </p>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex flex-column align-items-center"
          >
            <div className="colour-wrapper">
              <Image src={hospitalIcon} alt="" className="icons" fluid />
            </div>
            <div className="collab-style-line"></div>
            <p className="mini-text-style">
              Engagement with <br />
              Client Hospitals
            </p>
          </Col>
        </Row>
        {/* Scope of Service Section  */}

        <Row className="d-flex flex-row no gutters mt-4 mb-3 service-container">
          <Col sm={12} md={6} lg={6}>
            <div className="text-wrap">
              <h3 className="mini-title-style-white pt-5">Scope of Services</h3>
              <div className="scope-style-line"></div>
              <div className="services-text">
                <p className="pt-4 pb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea com modo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit Lorem ipsum
                  dolor
                </p>
                <Link to="/services">
                  <Button className="">Click for Service Information</Button>
                </Link>
              </div>
            </div>
          </Col>

          <Col sm={12} md={6} lg={6} className="pl-0 pr-0">
            <Image src={medicalImage} alt="" className="image-sizing" />
          </Col>
        </Row>

        {/* Regional Section  */}
        <Row className="mt-5 mb-4">
          <Col md={12} lg={12}>
            <h3 className="heading-style">
              Regional Challenges and <span className="green">Solutions</span>
            </h3>
            <div className="mini-title-style-green"></div>
          </Col>
        </Row>

        <Row className="pl-0 pr-0 mb-5 no gutters justify-content-center">
          <Col
            sm={12}
            md={8}
            lg={4}
            className="green-background pb-3 mb-3 pl-4"
          >
            <div className="text-box-style pt-3">
              <h4>Mega Trends</h4>
              <div className="text-box-underline"></div>
              <div className="mini-text-box mt-4">
                <h5>Challenge:</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation.
                </p>
              </div>
              <div className="mini-text-box mt-3">
                <h5>Solution:</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </Col>

          <Col sm={4} md={4} lg={2} className="pl-0 pr-0 mb-3">
            <Image src={research} alt="research image" className="image-fill" />
          </Col>

          <Col sm={12} md={8} lg={4} className="green-background pb-3 mb-3">
            <div className="text-box-style pt-3">
              <h4>Supply Bottlenecks</h4>
              <div className="text-box-underline"></div>
              <div className="mini-text-box mt-4">
                <h5>Challenge:</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation.
                </p>
              </div>
              <div className="mini-text-box mt-3">
                <h5>Solution:</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} className="pl-0 pr-0 mb-3">
            <Image src={tablets} alt="research image" className="image-fill" />
          </Col>
        </Row>

        {/* Compliance Section */}
        <Row className="no gutters">
          <div className="full-width d-flex flex-row">
            <Col sm={12} md={7} lg={7} className="mt-4 ">
              <div className="compliance-box mb-4">
                <h3>Compliance</h3>
                <div className="compliance-underline"></div>

                <p className="special-text mt-5 mb-0">
                  We are fully compliant with
                </p>
                <p className="special-text-bold mt-0">
                  UAE Regulations and licenses.
                </p>
                <p>
                  For further information about these continue over to our
                  compliance page, which will give a full overview on everything
                  you need to know.
                </p>

                <Link to="/compliance">
                  <Button className="compliance-btn">
                    Click For Compliance Information
                  </Button>
                </Link>
              </div>
            </Col>
            <Col sm={1} md={7} lg={5} className="compliance-image">
              <Image src={complianceIcon} alt="" className="pt-5 pb-5  pr-0" />
            </Col>
          </div>
        </Row>

        {/* Form section */}

        <Row className="mt-5 mb-4">
          <div className="contact-container">
            <Col md={12} lg={12}>
              <h3 className="contact-styling">Contact Us</h3>
              <div className="mini-title-style-green"></div>
            </Col>
          </div>
        </Row>

        <Row className="d-flex flex-column">
          <div className="contact-container-form">
            <Form
              action="mailto:info@sensala.com"
              method="get"
              enctype="text/plain"
            >
              <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <FormControl placeholder="First Name" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>Last Name</Form.Label>
                  <FormControl placeholder="Last Name" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridCompany">
                  <Form.Label>Company</Form.Label>
                  <FormControl placeholder="Company Name" />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridSubject">
                  <Form.Label>Subject</Form.Label>
                  <FormControl placeholder="Subject" />
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="exampleForm.ControlTextarea">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows="3" />
              </Form.Group>
              <div className="btn-holder">
                <Button className="form-btn" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Row>
      </Container>
    );
  }
}
export default Home;
