import React from "react";
import { Link } from "react-router-dom";
import sensalaFooterLogo from "../../static/img/sensalaFooterLogo.svg";
import "./Footer.css";

function Footer() {
  return (
    <div className="Footer flex-direction">
      <Link to="/">
        <img src={sensalaFooterLogo} alt="Logo" className="logo" />
        <p>© 2020 Sensala. All rights reserved.</p>
      </Link>
    </div>
  );
}

export default Footer;
