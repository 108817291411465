import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ItemsPage from "../ItemsPage/ItemsPage";
import NavBackend from "../../components/NavBackend/NavBackend";
import SideNavDashboard from "../../components/SideNavDashboard/SideNavDashboard";
import "./DashboardPage.css";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
  }

  handleSelectItem = (item) => {
    this.props.selectItem(item);
  };

  render() {
    return (
      <Container className="DashboardPage" fluid>
        <Row className="no gutters">
          <Col className="pl-0 pr-0 pt-4 grey" sm={12} md={12} lg={12}>
            <NavBackend />
          </Col>
        </Row>

        <Row className="mainrow justify-content-md-center">
          <Col className="sidenav-style" sm={12} md="auto" lg={4}>
            <SideNavDashboard />
          </Col>
          <Col sm={12} md={8} lg={8}>
            <ItemsPage handleSelectItem={this.handleSelectItem} />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.select.item,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectItem: (item) => dispatch({ type: "SELECT_ITEM", item: item }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
