import { combineReducers } from "redux";
import authReducer from "./authReducer";
import itemReducer from "./itemReducer";

const allReducers = combineReducers({
  auth: authReducer,
  select: itemReducer,
});

export default allReducers;
