import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavHeader from "../../components/NavHeader/NavHeader";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import pinIcon from "../../static/img/ContactImages/pin.svg";

class Contact extends React.Component {
  render() {
    return (
      <Container className="Contact" fluid>
        <Row className="no gutters">
          <Col className="pl-0 pr-0" sm={12} md={12} lg={12}>
            <NavHeader />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="title-spacing">Contact Us</h1>
            <div className="underline-contact"></div>
          </Col>
        </Row>
        <Row>
          <Col md={7} lg={7} className="form pt-5">
            <Form
              action="mailto:info@sensala.com"
              method="get"
              enctype="text/plain"
            >
              <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <FormControl placeholder="First Name" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>Last Name</Form.Label>
                  <FormControl placeholder="Last Name" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridCompany">
                  <Form.Label>Company</Form.Label>
                  <FormControl placeholder="Company Name" />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridSubject">
                  <Form.Label>Subject</Form.Label>
                  <FormControl placeholder="Subject" />
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="exampleForm.ControlTextarea">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows="3" />
              </Form.Group>
              <div className="btn-holder">
                <Button className="form-btn" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
          <Col className="contact-line" md={1} lg={1}></Col>
          <Col className="centre-container" sm={12} md={4} lg={4}>
            <img src={pinIcon} alt=""></img>
            <h3 className="title-spacing">Location</h3>
            <div className="underline-category"></div>
            <p className="location-paragraph">Business Bay, Dubai.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact;
