import React from "react";
import { Switch, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBackend from "./components/NavBackend/NavBackend";
import Footer from "./components/Footer/Footer";
import Home from "./InformationPages/Home/Home";
import Services from "./InformationPages/Services/Services";
import Compliance from "./InformationPages/Compliance/Compliance";
import Contact from "./InformationPages/Contact/Contact";
import ItemsPage from "./Dashboard/ItemsPage/ItemsPage";
import SignUpPage from "./SignupPage/SignupPage";
import DashboardPage from "./Dashboard/DashboardPage/DashboardPage";
import LoginPage from "./LoginPage/LoginPage";
import requireAuth from "./hoc/requireAuth";
import requireNoAuth from "./hoc/requireNoAuth";
import itemDetails from "./Dashboard/ItemDetailsPage/itemDetails";

import "./App.css";

function App() {
  return (
    <Container className="container-style pl-0 pr-0" fluid>
      <Row className="no-gutters">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/compliance" component={Compliance} />
          <Route path="/contact" component={Contact} />
          <Route path="/itemdetails" component={itemDetails} />
          <Route path="/items" component={ItemsPage} />
          <Route path="/signin" component={requireNoAuth(LoginPage)} />
          <Route path="/signup" component={requireNoAuth(SignUpPage)} />
          <Route
            path="/profileManager"
            component={requireAuth(DashboardPage)}
          />
          <Route
            path="/profileDirector"
            component={requireAuth(DashboardPage)}
          />
        </Switch>
      </Row>
      <Footer />
    </Container>
  );
}

export default App;
