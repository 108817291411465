import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import "./NavHeader.css";

class NavHeader extends React.Component {
  render() {
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" className=" NavHeader">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="container-fluid ml-auto flex-lg-row align-items-end ">
              <Nav.Item>
                <Nav.Link>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <NavLink to="/services">Services</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <NavLink to="/compliance">Compliance</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <NavLink to="/contact">Contact</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-auto">
                <Nav.Link>
                  <button className="portal-button">
                    <NavLink to="/signin" className="portal-style">
                      Sensala Login
                    </NavLink>
                  </button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavHeader;
