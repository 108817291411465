import React from "react";
import "./ItemsPage.css";

import ItemCard from "../../components/ItemCard/ItemCard";
import { Container, Row, Col, Dropdown } from "react-bootstrap";

class ItemsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    fetch("/items")
      .then((results) => results.json())
      .then((results) => {
        console.log(results);
        this.setState({ items: results });
      })
      .catch((error) => console.error(error));
  }

  render() {
    return (
      <Container className="items-page" fluid>
        <Row className="mainrow no gutters ml-0 mr-0 justify-content-md-center">
          <Col className="items pl-0 pr-0" sm={12} md="auto" lg={12}>
            <h2 className="all-items">All Items</h2>
            <Row className="filter-style" xs={3} sm={3} md={4} lg={4}>
              <Col>
                <h4>Filter By:</h4>
              </Col>
              <Col>
                <div className="dropdown-group">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" id="dropdown-basic">
                      Category
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Devices</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Drugs</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Medical Consumables
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">
                        Surgical Implants
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle size="sm" id="dropdown-basic">
                      Expiration
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        {" "}
                        less than 3 months
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        less than 6 months
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        over 12 months
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Row className="no gutters">
              <Col className="all-items" sm={12} md="auto" lg={12}>
                <Row className="justify-content-center">
                  {this.state.items.map((item) => (
                    <ItemCard
                      key={item.item_Name}
                      name={item.item_Name}
                      category={item.category}
                      expiration={new Date(item.expiration_Date)
                        .toISOString()
                        .slice(0, 7)
                        .split("-")
                        .reverse()
                        .join("/")}
                      item={item}
                      handleSelectItem={this.props.handleSelectItem}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ItemsPage;
