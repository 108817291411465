import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Instrument from "../../static/img/ServiceImages/instruments.jpg";
import NavBackend from "../../components/NavBackend/NavBackend";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

import "./itemDetails.css";

class itemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    // this.props.history.goBack();
    if (this.props.user.role === "Director") {
      this.props.history.push("/profileDirector");
    } else {
      this.props.history.push("/profileManager");
    }
  }
  render() {
    let someDate = `${this.props.item.expiration_Date}`;
    let expiration = someDate
      .toString()
      .slice(0, 7)
      .split("-")
      .reverse()
      .join("/");

    return (
      <Container className="itemDetail" fluid>
        <Row className="no gutters">
          <Col className="pl-0 pr-0 pt-4 grey" sm={12} md={12} lg={12}>
            <NavBackend />
          </Col>
        </Row>

        <Row className="itemDetails-wrapper">
          <Col className="items-style" sm={12} md={12} lg={12}>
            <h2 className="product-name">{this.props.item.item_Name}</h2>

            <div className="color-container">
              <Row className="no gutters ml-0 mr-0">
                <Col
                  className="item-image-container pt-3"
                  sm={12}
                  md={6}
                  lg={6}
                >
                  <div className="item-image-wrapper mb-4">
                    <img
                      className="item-image mr-0"
                      src={Instrument}
                      height={320}
                      alt=""
                    />

                    <h4 className="expiring-banner">
                      expiring in <br />
                      <span>{expiration}</span>
                    </h4>
                  </div>
                </Col>
                <Col className="item-description pt-5" sm={5} md={6} lg={6}>
                  <div className="product-info">
                    {this.props.user.role === "Director" && (
                      <h3>
                        &euro;
                        {this.props.item.price}
                        /pc
                      </h3>
                    )}

                    <p>
                      Quantity Available:
                      {this.props.item.qty_Available}
                      pc
                    </p>
                  </div>
                  <p className="product-description">
                    {this.props.item.item_Description}
                  </p>
                  <div className="quanity-counter pt-5">
                    <p>Quantity:</p>
                    <div className="item-quantity">
                      <AiFillMinusCircle
                        className="icon-color"
                        onClick={() =>
                          this.setState(() => ({
                            count: Math.max(0, this.state.count - 1),
                          }))
                        }
                      >
                        >
                      </AiFillMinusCircle>
                      <input
                        className="counter-style"
                        pattern="[0-9]*"
                        value={this.state.count}
                        onChange={(event) =>
                          this.setState({
                            count: event.target.value.replace(/\D/, ""),
                          })
                        }
                      ></input>
                      <AiFillPlusCircle
                        className="icon-color"
                        onClick={() =>
                          this.setState(() => ({
                            count: Math.max(0, this.state.count + 1),
                          }))
                        }
                      ></AiFillPlusCircle>
                    </div>
                  </div>
                  <div className="request-button-wrapper">
                    <Link to="/request">
                      <Button className="request-button mt-3 mb-4">
                        Add to Request
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="items-button-wrapper">
              <Button className="items-button" onClick={this.goBack}>
                Back to ALL ITEMS
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.select.item,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(itemDetails);
