import React from "react";
import "./Services.css";
import NavHeader from "../../components/NavHeader/NavHeader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import drip from "../../static/img/ServiceImages/drip.jpg";
import instruments from "../../static/img/ServiceImages/instruments.jpg";
import pill from "../../static/img/ServiceImages/pill.jpg";
import surgery from "../../static/img/ServiceImages/surgery.jpg";
import data from "../../static/img/ServiceImages/data.jpg";
import meds from "../../static/img/ServiceImages/meds.jpg";

class Services extends React.Component {
  render() {
    return (
      <Container className="Service" fluid>
        <Row className="no gutters">
          <Col className="pl-0 pr-0" sm={12} md={12} lg={12}>
            <NavHeader />
          </Col>
        </Row>
        <Row className="services">
          <Col sm={12} md={12} lg={6} className="service-heading">
            <h1>Services</h1>
            <div className="service-line"></div>
          </Col>
          <Col sm={12} md={12} lg={1} className="pl-0 pr-0">
            <div className="line-divider mb-3"></div>
          </Col>

          <Col sm={12} md={12} lg={5} className="purpose pt-4">
            <h2>Purpose</h2>
            <div className="title-underline"></div>
            <p className="pt-3">
              Our wholesale businesses provide time-critical deliveries of
              pharmaceutical and consumer health products to retail and hospital
              pharmacies in 3 countries. We pride ourselves on providing
              tailored quality solutions that benefit all of our partners.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className="title-spacing">Categories</h1>
            <div className="underline-category"></div>
          </Col>
        </Row>

        <Row className="d-flex flex-row justify-content-center">
          <Col sm={12} md={4} lg={4}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={surgery}
                className="img-fluid"
                alt="surgery"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                    Medical <br /> Consumables
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia consequuntur magni dolores eos qui
                  ratione voluptatem sequi nesciunt.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={pill}
                className="img-fluid"
                alt="pill"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                    Pharmacy
                    <br /> Items
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia consequuntur magni dolores eos qui
                  ratione voluptatem sequi nesciunt.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={4} lg={4}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={drip}
                className="img-fluid"
                alt="drip"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                    Medical
                    <br /> Devices
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia consequuntur magni dolores eos qui
                  ratione voluptatem sequi nesciunt.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="d-flex flex-row justify-content-center">
          <Col sm={12} md={4} lg={4}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={instruments}
                className="img-fluid"
                alt="instruments"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                    Surgical
                    <br /> Implants
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia consequuntur magni dolores eos qui
                  ratione voluptatem sequi nesciunt. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur. Nemo enim ipsam voluptatem quia voluptas sit
                  aspernatur aut odit aut fugit, sed quia.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={data}
                className="img-fluid"
                alt="data"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                    Healthcare
                    <br /> insights
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  Healthcare Insights is a new division of United Drug Supply
                  Chain Services. The purpose of this division is to source new
                  data and analytics products for our suppliers. In providing
                  new and unique healthcare insights to pharmaceutical managers
                  and executives, we hope to build on our relationships and
                  improve clinical outcomes.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="d-flex flex-row works-heading">
          <Col sm={true} md={6} lg={6} className="how-box-colour">
            <h2>How it Works</h2>
            <div className="for-underline"></div>
            <p className="how-paragraph ">
              <ul className="list-style pl-4 pr-5">
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae.
                </li>
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab architecto beatae vitae dicta sunt explicabo.
                </li>
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architect.
                </li>
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa qua.
                </li>
              </ul>
            </p>
          </Col>

          <Col sm={true} md={6} lg={6} className="who-colour-box pl-5 ">
            <h2>Who it is For</h2>
            <div className="for-underline2"></div>
            <p className="who-paragraph">
              <ul className="pl-4 pr-0">
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae.
                </li>
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab architecto beatae vitae dicta sunt explicabo.
                </li>
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architect.
                </li>
                <li>
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa qua.
                </li>
              </ul>
            </p>
          </Col>
        </Row>

        <Row className="d-flex flex-row pt-4">
          <Col md={6} lg={6}>
            <h2>
              Why it can <span className="green">help</span>
            </h2>
            <div className="final-underline"></div>
            <p className="help-paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum
            </p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Image src={meds} className="med-style" alt="meds" fluid />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Services;
