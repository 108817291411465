import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUp from "../components/SignUp/SignUp";
import "./SignupPage.css";

function SignupPage() {
  return (
    <Container className="signup ml-5 mr-5" fluid>
      <Row>
        <Col sm={12} xs={12} md={12} lg={12} className="signup">
          <h1>SIGN UP</h1>
          <SignUp />
        </Col>
      </Row>
      <Row className="Signupbtn mt-5">
        <Button variant="danger">
          <Link to="/">Go Back To Home Page</Link>
        </Button>
      </Row>
    </Container>
  );
}

export default SignupPage;
